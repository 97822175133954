import type { NuxtApp } from '#app'
import type { User } from '~/types/users'

export default defineNuxtPlugin(async (_nuxtApp: NuxtApp) => {
  const config = useRuntimeConfig().public.kvPermissions
  const user = useSanctumUser<User>()
  // const user = await useNuxtApp().$authorization?.resolveClientUser();

  const userPermissions = computed(() => user.value?.permissions || [])
  const userRoles = computed(() => user.value?.roles || [])

  function hasNotPermission(binding: string | string[] | undefined) {
    if (!binding)
      return true
    const activePermissions = typeof binding === 'string' ? [binding] : binding
    return !activePermissions.some(permission => userPermissions.value.includes(permission))
  }

  function hasPermission(binding: string | string[]) {
    if (userRoles.value.includes('Admin'))
      return true

    if (!binding)
      return true
    return !hasNotPermission(binding)
  }

  function hasNotRole(binding: string | string[] | undefined) {
    if (!binding)
      return true
    const activeRoles = typeof binding === 'string' ? [binding] : binding
    return !activeRoles.some(role => userRoles.value.includes(role))
  }

  function hasRole(binding: string | string[]) {
    if (!binding)
      return true
    const fullAccessRoles
      = typeof config.fullAccessRoles === 'string'
        ? [config.fullAccessRoles]
        : config.fullAccessRoles
    if (fullAccessRoles && fullAccessRoles.some(role => userRoles.value.includes(role))) {
      return true
    }
    return !hasNotRole(binding)
  }

  _nuxtApp.vueApp.directive('can', {
    mounted(el, binding) {
      if (binding.arg === 'not') {
        if (hasPermission(binding.value)) {
          el.remove()
        }
        return
      }

      if (!hasPermission(binding.value)) {
        el.remove()
      }
    },
  })

  _nuxtApp.vueApp.directive('role', {
    mounted(el, binding) {
      if (binding.arg === 'not') {
        if (hasRole(binding.value)) {
          el.remove()
        }
        return
      }
      if (!hasRole(binding.value)) {
        el.remove()
      }
    },
  })

  return {
    provide: {
      hasPermission,
      hasRole,
    },
  }
})
