import { defineNuxtPlugin } from '#app'
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'

declare global {
  interface Window {
    Pusher: any
    Echo: any
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig().public

  window.Pusher = Pusher

  // build a pusher client
  const pusher = new Pusher(config.pusher.appKey, {
    cluster: 'mt1',
  })

  window.Echo = new Echo({
    broadcaster: 'pusher',
    client: pusher,
  })

  // expose pusher to the app
  nuxtApp.provide('pusher', pusher)
})
