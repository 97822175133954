export const useSharedStore = defineStore('useSharedStore', () => {
  const getBaseUrl = computed<string>(() => '/api/v1/reservations')
  const currentReservationID = ref<number>(null)
  const getUrlReservation = computed<string>(
    () => `/api/v1/reservations/${currentReservationID.value}`,
  )
  const setCurrentReservationId = async (id: number) => (currentReservationID.value = id)

  return {
    getBaseUrl,
    currentReservationID,
    getUrlReservation,
    setCurrentReservationId,
  }
})
