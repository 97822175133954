import payload_plugin_kVYxpTaFEp from "/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.34.1/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_tumO7VZOxP from "/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.20.0_jiti@2.4._cb6ecaeb364b149872e777f72bd09876/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_CFEW4NNPVN from "/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.20.0_jiti@2.4._cb6ecaeb364b149872e777f72bd09876/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_nRtMdl6ebc from "/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.20.0_jiti@2.4._cb6ecaeb364b149872e777f72bd09876/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_iY66Qiaqmv from "/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.20.0_jiti@2.4._cb6ecaeb364b149872e777f72bd09876/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_6980khSO26 from "/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.20.0_jiti@2.4._cb6ecaeb364b149872e777f72bd09876/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_EAi5jUBCLe from "/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.20.0_jiti@2.4._cb6ecaeb364b149872e777f72bd09876/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_jlJhceEJVB from "/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.20.0_jiti@2.4._cb6ecaeb364b149872e777f72bd09876/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_UKfX7vgTCz from "/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.34.1/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_zlvi6dcIsi from "/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_Ae0oVA6lu8 from "/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.20.0_jiti@2.4._cb6ecaeb364b149872e777f72bd09876/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_te6vEKtai8 from "/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/node_modules/.pnpm/@nuxtjs+algolia@1.10.2_@vue+server-renderer@3.5.13_vue@3.5.13_typescript@5.7.3___magica_6d7989001cf8aa41ffca56744672b6c1/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import plugin_client_M7xx6SHTJD from "/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/node_modules/.pnpm/nuxt-laravel-echo@0.2.1_magicast@0.3.5_rollup@4.34.1/node_modules/nuxt-laravel-echo/dist/runtime/plugin.client.js";
import plugin_pyIek0o4QF from "/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/node_modules/.pnpm/nuxt-auth-sanctum@0.5.4_magicast@0.3.5_rollup@4.34.1/node_modules/nuxt-auth-sanctum/dist/runtime/plugin.js";
import pwa_icons_plugin_iRYm12t59B from "/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/node_modules/.cache/nuxt/.nuxt/pwa-icons-plugin.ts";
import pwa_client_77UGhg3rjd from "/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/node_modules/.pnpm/@vite-pwa+nuxt@0.10.6_magicast@0.3.5_rollup@4.34.1_vite@6.0.11_@types+node@22.13.1_jiti_682a848225160021a9a93f2e796df821/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import slideovers_wdO27ROloI from "/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/node_modules/.pnpm/@nuxt+ui@2.21.0_axios@1.7.9_change-case@5.4.4_magicast@0.3.5_rollup@4.34.1_typescript@5_d0faa34cebe747dba406c3d1110165bb/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_hW71JttoJA from "/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/node_modules/.pnpm/@nuxt+ui@2.21.0_axios@1.7.9_change-case@5.4.4_magicast@0.3.5_rollup@4.34.1_typescript@5_d0faa34cebe747dba406c3d1110165bb/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_RcS9sHiVDe from "/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/node_modules/.pnpm/@nuxt+ui@2.21.0_axios@1.7.9_change-case@5.4.4_magicast@0.3.5_rollup@4.34.1_typescript@5_d0faa34cebe747dba406c3d1110165bb/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_4DvtYnIvWw from "/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.34.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_kYzowwhV0D from "/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_rollup@4.34.1_vite@6.0.11_@types+node@22.13.1_jiti@2.4_c1f451526fe33818919065757036c6ca/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import permissions_JVscFKjjvB from "/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/app/plugins/permissions.ts";
import api_slUmq5TON0 from "/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/app/plugins/api.ts";
import authorization_resolver_iPwPYlSkne from "/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/app/plugins/authorization-resolver.ts";
import presets_KPJ9sWPSmK from "/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/app/plugins/presets.ts";
import pusher_client_rhUyMCd7M6 from "/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/app/plugins/pusher.client.ts";
import stores_2PIOSorjhs from "/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/app/plugins/stores.ts";
export default [
  payload_plugin_kVYxpTaFEp,
  revive_payload_client_tumO7VZOxP,
  unhead_CFEW4NNPVN,
  router_nRtMdl6ebc,
  payload_client_iY66Qiaqmv,
  navigation_repaint_client_6980khSO26,
  check_outdated_build_client_EAi5jUBCLe,
  chunk_reload_client_jlJhceEJVB,
  plugin_vue3_UKfX7vgTCz,
  components_plugin_zlvi6dcIsi,
  prefetch_client_Ae0oVA6lu8,
  plugin_te6vEKtai8,
  plugin_client_M7xx6SHTJD,
  plugin_pyIek0o4QF,
  pwa_icons_plugin_iRYm12t59B,
  pwa_client_77UGhg3rjd,
  slideovers_wdO27ROloI,
  modals_hW71JttoJA,
  colors_RcS9sHiVDe,
  plugin_client_4DvtYnIvWw,
  plugin_kYzowwhV0D,
  permissions_JVscFKjjvB,
  api_slUmq5TON0,
  authorization_resolver_iPwPYlSkne,
  presets_KPJ9sWPSmK,
  pusher_client_rhUyMCd7M6,
  stores_2PIOSorjhs
]