import type { NuxtApp } from '#app'
import type { ConsolaInstance } from 'consola'
// LocalStorage example for Laravel Authentication token
import type { FetchContext } from 'ofetch'
import type { TokenStorage } from '~/types'

const tokenStorageKey = 'sanctum.storage.token'
const localTokenStorage: TokenStorage = {
  get: async () => {
    if (import.meta.server) {
      return undefined
    }
    return window.localStorage.getItem(tokenStorageKey) ?? undefined
  },

  set: async (app: NuxtApp, token?: string) => {
    if (import.meta.server) {
      return
    }
    if (!token) {
      window.localStorage.removeItem(tokenStorageKey)
      return
    }
    window.localStorage.setItem(tokenStorageKey, token)
  },
}

export default defineAppConfig({
  titleApp: 'Kovay Vacations',
  version: '0.0.2',
  sanctum: {
    mode: 'token',
    tokenStorage: localTokenStorage,
    interceptors: {
      onRequest: async (app: NuxtApp, ctx: FetchContext, logger: ConsolaInstance) => {
        logger.debug(`custom onRequest interceptor (${ctx.request})`)
      },
      onResponse: async (app: NuxtApp, ctx: FetchContext, logger: ConsolaInstance) => {
        logger.debug(`custom onResponse interceptor (${ctx.response})`)

        if (ctx.response.status === 403) {
          await app.runWithContext(() => navigateTo('/'))
        }
      },
    },
  },
  ui: {
    primary: 'kovay',
    gray: 'neutral',
    strategy: 'merge',
    icons: {
      dark: 'i-heroicons-moon-20-solid',
      light: 'i-heroicons-sun-20-solid',
      loader: 'i-lucide-loader',
      system: 'i-heroicons-computer-desktop-20-solid',
      search: 'i-heroicons-magnifying-glass-20-solid',
      external: 'i-heroicons-arrow-up-right-20-solid',
      chevron: 'i-heroicons-chevron-down-20-solid',
      hash: 'i-heroicons-hashtag-20-solid',
      menu: 'i-heroicons-bars-3-20-solid',
      close: 'i-heroicons-x-mark-20-solid',
      check: 'i-heroicons-check-circle-20-solid',
    },
    presets: {
      button: {
        primary: {
          color: 'white',
          variant: 'solid',
        },
        secondary: {
          color: 'gray',
          variant: 'ghost',
        },
        input: {
          color: 'white',
          variant: 'outline',
          ui: {
            font: '',
            color: {
              white: {
                outline:
                  'ring-1 ring-inset ring-gray-300 dark:ring-gray-700 hover:ring-gray-300 dark:hover:ring-gray-700 hover:bg-gray-100/50 dark:hover:bg-gray-800/50 text-gray-400 dark:text-gray-500 hover:text-gray-700 dark:hover:text-gray-200 focus-visible:ring-2 focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400',
              },
            },
          },
        },
      },
    },
    card: {
      rounded: 'rounded-lg',
    },
    table: {
      th: {
        base: 'text-left rtl:text-right bg-gray-100',
        padding: 'px-4 py-3',
        color: 'text-primary-600 dark:text-white',
        font: 'font-semibold uppercase',
        size: 'text-xs',
      },
      td: {
        padding: 'px-3 py-2',
      },
      default: {
        sortButton: {
          icon: 'i-heroicons-arrows-up-down-20-solid',
          trailing: true,
          square: true,
          color: 'gray',
          variant: 'ghost',
          class: '-m-1.5 text-primary-600 dark:text-white uppercase text-xs',
        },
        loadingState: {
          icon: 'i-lucide-loader',
          label: 'Loading...',
        },
      },
    },
    selectMenu: {
      option: {
        disabled: 'cursor-not-allowed opacity-50 bg-gray-100',
      },
    },
    formGroup: {
      error: 'mt-1 text-red-500 dark:text-red-400 text-xs',
    },
    input: {
      base: 'relative block w-full disabled:cursor-not-allowed disabled:opacity-75 focus:outline-none border-0 disabled:bg-gray-100',
      color: {
        white: {
          outline:
            'shadow-sm bg-white dark:bg-gray-900 text-gray-900 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-gray-700 focus:ring-1 focus:ring-primary-500 dark:focus:ring-primary-400',
        },
      },
    },
  },
})
