import { useSharedStore } from '~/store/useSharedStore'
import { useUserStore } from '~/store/useUserStore'

export default defineNuxtPlugin(() => {
  return {
    provide: {
      sharedStore: useSharedStore(),
      userStore: useUserStore(),
    },
  }
})
