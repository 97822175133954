import type { User } from '~/types/users'

export const useUserStore = defineStore('useUserStore', () => {
  const sanctumFetch = useSanctumClient()
  const user = ref<User | null>(null)
  const sanctumConfig = useSanctumAppConfig()
  const { refreshIdentity } = useSanctumAuth()

  const userPermissions = useCookie('permissions')

  const setUser = (newUser: User) => {
    user.value = newUser
  }

  const getUser = async () => {
    return useSanctumUser()
  }

  const setPermissions = (permissions: string[]) => {
    // @ts-ignore
    userPermissions.value = permissions
  }

  const clearUser = () => {
    user.value = null
  }

  interface Column {
    id: number
    name: string
    email: string
    [key: string]: unknown
  }

  const loginAs = async (item: Column) => {
    const response = await sanctumFetch(`/api/v1/login-as/${item.id}`, { method: 'POST' })
    if (response?.token) {
      await sanctumConfig.tokenStorage.set(useNuxtApp(), response.token)
      await refreshIdentity()
      await navigateTo(
        {
          path: '/',
        },
        { replace: true },
      )
    }
  }

  const deleteUser = async (id: number) => {
    return new Promise((resolve, reject) => {
      try {
        const response = sanctumFetch(`/api/v1/users/${id}`, {
          method: 'DELETE',
        })
        resolve(response)
      }
      catch (e) {
        reject(e)
      }
    })
  }

  return {
    user,
    setUser,
    getUser,
    clearUser,
    deleteUser,
    setPermissions,
    loginAs,
  }
})
