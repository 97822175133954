export default defineNuxtPlugin(async (nuxtApp) => {
  const config = useAppConfig().sanctum
  const sanctumConfig = useSanctumConfig()

  const token: string = await config.tokenStorage.get()

  const api = $fetch.create({
    baseURL: sanctumConfig.baseUrl,
    onRequest({ options }) {
      if (token) {
        const headers: HeadersInit = (options.headers ||= {})
        if (Array.isArray(headers)) {
          headers.push(['Authorization', `Bearer ${token}`])
        }
        else if (headers instanceof Headers) {
          headers.set('Authorization', `Bearer ${token}`)
        }
        else {
          headers.Authorization = `Bearer ${token}`
        }
      }
    },
    async onResponseError({ response }) {
      if (response.status === 401) {
        await nuxtApp.runWithContext(() =>
          navigateTo(sanctumConfig.redirect.onLogout || '/auth/login'),
        )
      }
    },
  })

  return {
    provide: {
      api,
    },
  }
})
