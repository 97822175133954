
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as loginPV7jXtwxikMeta } from "/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/app/pages/auth/login.vue?macro=true";
import { default as indexBm2962f9juMeta } from "/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/app/pages/index.vue?macro=true";
import { default as agencies_45reportXxFLwcD4zNMeta } from "/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/app/pages/reports/agencies-report.vue?macro=true";
import { default as arrivals_45without_45flightoTSS3O33HIMeta } from "/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/app/pages/reports/arrivals-without-flight.vue?macro=true";
import { default as indexDc6ZSaUcknMeta } from "/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/app/pages/reports/index.vue?macro=true";
import { default as total_45reservationsEcqJkBqBn5Meta } from "/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/app/pages/reports/total-reservations.vue?macro=true";
import { default as week_45pre_45manifesttc8qhnAP7cMeta } from "/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/app/pages/reports/week-pre-manifest.vue?macro=true";
import { default as editXlIdSTuvtvMeta } from "/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/app/pages/reservations/[id]/edit.vue?macro=true";
import { default as viewrJAg7Aukq1Meta } from "/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/app/pages/reservations/[id]/view.vue?macro=true";
import { default as createmJVgrtonEhMeta } from "/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/app/pages/reservations/create.vue?macro=true";
import { default as indexAqqYQ1NQrYMeta } from "/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/app/pages/reservations/index.vue?macro=true";
import { default as accountBZlnj8YoIQMeta } from "/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/app/pages/settings/account.vue?macro=true";
import { default as indexQ5hNeRU3r7Meta } from "/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/app/pages/settings/index.vue?macro=true";
import { default as permissionsKL83DTqBnDMeta } from "/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/app/pages/settings/roles/[id]/permissions.vue?macro=true";
import { default as editMyXFuKJrvIMeta } from "/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/app/pages/users/[id]/edit.vue?macro=true";
import { default as createCBW49WETJsMeta } from "/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/app/pages/users/create.vue?macro=true";
import { default as index6cLBnWlBvRMeta } from "/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/app/pages/users/index.vue?macro=true";
export default [
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginPV7jXtwxikMeta || {},
    component: () => import("/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/app/pages/auth/login.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexBm2962f9juMeta || {},
    component: () => import("/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/app/pages/index.vue")
  },
  {
    name: "reports-agencies-report",
    path: "/reports/agencies-report",
    meta: agencies_45reportXxFLwcD4zNMeta || {},
    component: () => import("/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/app/pages/reports/agencies-report.vue")
  },
  {
    name: "reports-arrivals-without-flight",
    path: "/reports/arrivals-without-flight",
    meta: arrivals_45without_45flightoTSS3O33HIMeta || {},
    component: () => import("/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/app/pages/reports/arrivals-without-flight.vue")
  },
  {
    name: "reports",
    path: "/reports",
    component: () => import("/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/app/pages/reports/index.vue")
  },
  {
    name: "reports-total-reservations",
    path: "/reports/total-reservations",
    meta: total_45reservationsEcqJkBqBn5Meta || {},
    component: () => import("/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/app/pages/reports/total-reservations.vue")
  },
  {
    name: "reports-week-pre-manifest",
    path: "/reports/week-pre-manifest",
    meta: week_45pre_45manifesttc8qhnAP7cMeta || {},
    component: () => import("/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/app/pages/reports/week-pre-manifest.vue")
  },
  {
    name: "reservations-id-edit",
    path: "/reservations/:id()/edit",
    meta: editXlIdSTuvtvMeta || {},
    component: () => import("/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/app/pages/reservations/[id]/edit.vue")
  },
  {
    name: "reservations-id-view",
    path: "/reservations/:id()/view",
    meta: viewrJAg7Aukq1Meta || {},
    component: () => import("/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/app/pages/reservations/[id]/view.vue")
  },
  {
    name: "reservations-create",
    path: "/reservations/create",
    meta: createmJVgrtonEhMeta || {},
    component: () => import("/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/app/pages/reservations/create.vue")
  },
  {
    name: "reservations",
    path: "/reservations",
    component: () => import("/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/app/pages/reservations/index.vue")
  },
  {
    name: "settings-account",
    path: "/settings/account",
    component: () => import("/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/app/pages/settings/account.vue")
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/app/pages/settings/index.vue")
  },
  {
    name: "settings-roles-id-permissions",
    path: "/settings/roles/:id()/permissions",
    meta: permissionsKL83DTqBnDMeta || {},
    component: () => import("/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/app/pages/settings/roles/[id]/permissions.vue")
  },
  {
    name: "users-id-edit",
    path: "/users/:id()/edit",
    meta: editMyXFuKJrvIMeta || {},
    component: () => import("/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/app/pages/users/[id]/edit.vue")
  },
  {
    name: "users-create",
    path: "/users/create",
    meta: createCBW49WETJsMeta || {},
    component: () => import("/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/app/pages/users/create.vue")
  },
  {
    name: "users",
    path: "/users",
    component: () => import("/home/runner/work/AppKovayNuxt3/AppKovayNuxt3/app/pages/users/index.vue")
  }
]